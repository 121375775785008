import { Component, OnInit, Input, Output, EventEmitter, } from '@angular/core';
import { UtilsService} from '../../../../helpers/services/index';

@Component({
  selector: 'app-subcontractor-card',
  templateUrl: './subcontractor-card.component.html',
  styleUrls: ['./subcontractor-card.component.scss']
})
export class SubcontractorCardComponent implements OnInit {

  @Input() resource: any;
  @Input() contacts: any;
  @Output() edit = new EventEmitter<Object>();

  constructor(public utils: UtilsService) { }

  ngOnInit() {
  }

  onEdit(data) {
    this.edit.emit(data);
  }


  isActive(contractStartDate: string, contractEndDate: string): boolean {
    const today = new Date();
    const startDate = new Date(contractStartDate);
    const endDate = new Date(contractEndDate);
    return today >= startDate && today <= endDate;
  }

  isExpiringSoon(contractEndDate: string): boolean {
    const today = new Date();
    const endDate = new Date(contractEndDate);
    const timeDifference = endDate.getTime() - today.getTime();
    const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
    return daysDifference > 0 && daysDifference <= 7;
  }

  isExpired(contractEndDate: string): boolean {
    const today = new Date();
    const endDate = new Date(contractEndDate);
    return today > endDate;
  }

  getSelectedListItem(selectedItem: any, contractItem: any) {
    switch (selectedItem) {
      case "View Contract":
        window.open(contractItem, '_blank');
        break;
    }
  }
}

