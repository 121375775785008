import { Injectable } from '@angular/core';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';
import { DataService } from '../core/interceptor.service';

@Injectable()
export class TimeService {
  info: any = [];
  constructor(private dataService: DataService) { }

  saveTaskActivity(taskActivity) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.SAVE_TASK_FOR_PROJECT, taskActivity);
  }

  updateTaskActivity(taskActivity) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_TASK_FOR_PROJECT, taskActivity);
  }
  deleteTaskActivity(taskActivity) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_TASK_FOR_PROJECT, taskActivity);
  }

  getWeekActivity(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_WEEK_ACTIVITY, req);
  }

  getTimeStreak(req) {
    return this.dataService.get(KRONOS.BASE + KRONOS.USER + API.GET_TIME_STREAK + req);
  }
}
